import { sendDataLayerEvent } from "./dataLayer";

$(() => {
  // kebab-menu-marketplace-link GTM
  $("#kebab-menu-marketplace-link").on("click", () => {
    sendDataLayerEvent("ClickOnMarketplaceHomeFromKebabMenu");
  });

  // marketplace-category-node-link GTM
  $(".marketplace-category-node-link").on("click", () => {
    sendDataLayerEvent("ClickOnAllMarketplaceListingFromMCN");
  });
});
